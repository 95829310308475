const path = require("path");

const isBrowser = typeof window !== "undefined";

module.exports = {
  i18n: {
    defaultLocale: "en",
    locales: ["en", "es"], //, "es", "nb"],
    localeDetection: false,
  },
  ns: ["common"], //, "dynamic", "countries", "ingredients", "steps", "units"], // the namespaces needs to be listed here, to make sure they got preloaded
  localePath: path.resolve("./public/locales"),
  keySeparator: false,
  returnEmptyString: false,
  missingKeyNoValueFallbackToKey: true,
  /*
  backend: {
    projectId: "0ee9d5eb-1364-4632-a92b-d9009da7127e",
    apiKey: "5728b423-692f-4bec-ba14-04fe171105c7",
    referenceLng: "en",
    allowedAddOrUpdateHosts: ["localhost"],
  },
  use: [require("i18next-locize-backend/cjs")],
  serializeConfig: false, // because of the custom use i18next plugin
  saveMissing: process.env.NODE_ENV === "development" && isBrowser,
  */
};
