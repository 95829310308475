import "styles/globals.scss";

import { useEffect, useState, useRef } from "react";
import { useRouter } from "next/router";

import Head from "next/head";
import { appWithTranslation } from "next-i18next";
import nextI18NextConfig from "../next-i18next.config.js";

import { init } from "@socialgouv/matomo-next";
import TagManager from "react-gtm-module";

import { useTranslation } from "next-i18next";

import store from "store2";
import urlSlug from "url-slug";

import {
  SessionProvider,
  useSession,
  signIn,
  getSession,
} from "next-auth/react";

import { config } from "@fortawesome/fontawesome-svg-core";
import "../node_modules/@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;

const sessionOptions = {
  refetchInterval: 5 * 60,
};

const usePreviousUrl = () => {
  const { asPath } = useRouter();
  const ref = useRef(null);
  useEffect(() => {
    ref.current = window ? `${window.location.origin}${asPath}` : null;
  }, [asPath]);

  return ref.current;
};

function MyApp({ Component, pageProps: { ...pageProps } }) {
  const [currentPath, setCurrentPath] = useState(null);
  const { t } = useTranslation();
  const getLayout = Component.getLayout || ((page) => page);
  const router = useRouter();
  const MATOMO_URL = "https://cocktailclub.matomo.cloud/";
  const MATOMO_SITE_ID = "1";
  useEffect(() => {
    init({
      url: MATOMO_URL,
      siteId: MATOMO_SITE_ID,
      excludeUrlsPatterns: [/\?token=.+/, /^\/admin/],
    });
  }, []);

  useEffect(() => {
    store.session.remove("prevPath");
  }, []);

  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-NNXLPGK" });
  }, []);

  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init("1065050857513443");
        ReactPixel.pageView();

        router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      });
  }, [router.events]);

  useEffect(() => {
    if (window) {
      if (currentPath) {
        store.session("prevPath", `${window.location.origin}${currentPath}`);
      } else {
        store.session.remove("prevPath");
      }
      setCurrentPath(router.asPath);
      router.beforePopState(({ as }) => {
        store.remove("comingFrom");
        if (as !== router.asPath) {
          store("comingFrom", router.asPath);
        }
        return true;
      });

      const routeChangeStart = () => {
        const key = urlSlug(`scroll-${router.asPath}`);
        const scrollTop = document.documentElement.scrollTop;
        if (scrollTop > 0) {
          store(key, scrollTop);
        }
      };

      router.events.on("routeChangeStart", routeChangeStart);
      return () => {
        router.beforePopState(() => true);
        router.events.off("routeChangeStart", routeChangeStart);
      };
    }
  }, [router.asPath]);
  return (
    <>
      <Head>
        <title key="title">Cocktail Club - Free drink recipes</title>
        <link
          rel="preconnect"
          href={`${process.env.NEXT_PUBLIC_REST_API_URL}`}
        />
        <meta
          key="description"
          name="description"
          content="Cocktail club is a recipe portal with recipes published in a manner that makes your life as a home bartender easy."
        />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/apple-touch-icon.png"
        />
        <link
          rel="shortcut icon"
          type="image/png"
          sizes="72x72"
          href="/img/favicon-72.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="72x72"
          href="/img/favicon-72.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/img/favicon-32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/img/favicon-16.png"
        />
        <meta name="theme-color" content="#ffffff" />
        <meta
          key="robots"
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:site_name" content="Cocktail Club" />
        <meta property="fb:page_id" content="254744918579498" />
        <meta property="fb:app_id" content="302205355379729" />
        <meta property="og:locale" content="en_US" />
        <meta key="ogType" property="og:type" content="website" />
        <meta
          key="ogTitle"
          property="og:title"
          content="Cocktail Club - Free drink recipes"
        />
        <meta
          key="ogDescription"
          property="og:description"
          content="Cocktail club is a recipe portal with recipes published in a manner that makes your life as a home bartender easy."
        />
        <meta
          key="ogImage"
          property="og:image"
          content="https://cocktailclub.com/img/thumbnail.jpg"
        />
        <meta
          key="ogUrl"
          property="og:url"
          content="https://cocktailclub.com"
        />
        <link key="canonical" rel="canonical" href="https://cocktailclub.com" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@cocktailclub" />
        <meta name="twitter:creator" content="@cocktailclub" />
      </Head>
      <SessionProvider session={pageProps.session}>
        {Component.auth ? (
          <Auth {...Component.auth}>
            {Component.getLayout ? (
              getLayout(<Component {...pageProps} />, t)
            ) : (
              <Component {...pageProps} />
            )}
          </Auth>
        ) : Component.getLayout ? (
          getLayout(<Component {...pageProps} />, t)
        ) : (
          <Component {...pageProps} />
        )}
        <div id="modal-root"></div>
      </SessionProvider>
    </>
  );
}

function Auth({ children, roles, redirectTo }) {
  //const { data: session, status } = useSession();
  //const loading = status === "loading";

  const [session, setSession] = useState();
  const [loading, setLoading] = useState(true);

  const router = useRouter();
  const isUser = !!session?.user;

  useEffect(() => {
    const fetchSession = async () => {
      setSession(await getSession());
      setLoading(false);
    };
    fetchSession();
  }, []);

  useEffect(() => {
    if (loading) return;
    if (roles && isUser) {
      if (!roles.includes(session.user.role)) {
        return router.push(redirectTo || "/login");
      }
    }
    if (!isUser) signIn();
  }, [isUser, router, session, redirectTo, roles]);

  if (isUser) {
    return children;
  }

  return <div>Loading...</div>;
}
export default appWithTranslation(MyApp, nextI18NextConfig);
